import React, { memo, useEffect, useState } from 'react';
import ProfilesService from '../../../services/ProfilesService';
import BUserAvatar from '../../utils/BUserAvatar';
import { Typography, makeStyles } from '@material-ui/core';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import BFooter from '../../layout/BFooter';
import { Link, generatePath } from 'react-router-dom';
import { ROUTES } from '../../BRouter';

type FP = typeof ProfilesService.instance.firstProfiles$;
type FPParams = Parameters<FP>;
type FPCallback = FPParams[1];
type FPCallbackParams = Parameters<FPCallback>;
type Profiles = FPCallbackParams[0];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: theme.gradients.brand,
    overflow: 'scroll',
    minHeight: '100vh',
    paddingBottom: 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    color: 'white',
    fontSize: 40,
    paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  subtitle: {
    color: 'white',
    marginBottom: 50,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)', // 6 columns by default
    gap: '20px', // spacing between grid items
    height: 'auto', // Fill the entire screen height
    width: '100%', // Fill the entire screen width
    // Media queries
    '@media (max-width: 1600px)': {
      gridTemplateColumns: 'repeat(6, 1fr)', // 4 columns for tablets
    },
    '@media (max-width: 992px)': {
      gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns for tablets
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns for large mobile screens
    },
    '@media (max-width: 576px)': {
      gridTemplateColumns: 'repeat(1, 1fr)', // 1 column for small mobile screens
    },
  },
  item: {
    backgroundColor: '#e0e0e0',
    border: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: '24px',
    borderRadius: 25,
    padding: 5,
    flexDirection: 'column',
    cursor: 'pointer',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  profileTitle: {
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
  },
  footerWrapper: {
    '& p': {
      marginTop: 50,
      color: 'white',
    },
  },
}));

const B1000 = () => {
  const content = useLocalizedContent('home_page');
  const styles = useStyles();
  const [profiles, setProfiles] = useState<Profiles>([]);

  useEffect(() => {
    ProfilesService.instance.firstProfiles$(1000, (profiles) => {
      setProfiles([...profiles]);
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Typography variant="h1" className={styles.title}>
        {content.header_title_top}
      </Typography>
      <Typography className={styles.subtitle}>
        {content.header_subtitle}
      </Typography>
      <div className={styles.container}>
        {profiles.map((profile, i) => (
          <div className={styles.item} key={`${profile} - ${i}`}>
            <Link
              to={generatePath(ROUTES.user, { userId: profile.id })}
              className={styles.link}
            >
              <BUserAvatar
                userId={profile.id}
                variant="rounded"
                style={{ width: '100%', height: 'auto', aspectRatio: '1' }}
              />
              <Typography className={styles.profileTitle}>
                {profile.username}
              </Typography>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(B1000);
